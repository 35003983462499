enum Action {
  ALL_USER_ORGS_LOADED = 'ALL_USER_ORGS_LOADED',
  ALL_SCREENS_LOADING = 'ALL_SCREENS_LOADING',
  USER_LOADING = 'USER_LOADING',
  ORG_LOADING = 'ORG_LOADING',
  ALL_SCREENS_LOADED = 'ALL_SCREENS_LOADED',
  ALL_TOURS_LOADING = 'ALL_TOURS_LOADING',
  ALL_TOURS_LOADED = 'ALL_TOURS_LOADED',
  SCREEN_LOADING = 'SCREEN_LOADING',
  CLEAR_CURRENT_SCREEN = 'CLEAR_CURRENT_SCREEN',
  CLEAR_CURRENT_TOUR = 'CLEAR_CURRENT_TOUR',
  SCREEN_AND_DATA_LOADED = 'SCREEN_AND_DATA_LOADED',
  SET_CUSTOM_DOMAIN = 'SET_CUSTOM_DOMAIN',
  TOUR = 'TOUR',
  SCREEN = 'SCREEN',
  DELETE_TOUR = 'DELETE_TOUR',
  LC_ORG_ID='LC_ORG_ID',
  TOUR_AND_DATA_LOADED = 'TOUR_AND_DATA_LOADED',
  TOUR_AND_LOADER_LOADED = 'TOUR_AND_LOADER_LOADED',
  SAVE_TOUR_LOADER='SAVE_TOUR_LOADER',
  SAVE_EDIT_CHUNKS = 'SAVE_EDIT_CHUNKS',
  SAVE_GLOBAL_EDIT_CHUNKS = 'SAVE_GLOBAL_EDIT_CHUNKS',
  SAVE_TOUR_ENTITIES = 'SAVE_TOUR_ENTITIES',
  GENERIC_LOADING = 'GENERIC_LOADING',
  TOUR_LOADING = 'TOUR_LOADING',
  OPS_IN_PROGRESS = 'OPS_IN_PROGRESS',
  INIT = 'INIT',
  IAM = 'IAM',
  UPDATE_USER_PROP = 'UPDATE_USER_PROP',
  SAVE_TOUR_RELAY_ENTITIES = 'SAVE_TOUR_RELAY_ENTITIES',
  CLEAR_RELAY_SCREEN_ANN_ADD = 'CLEAR_RELAY_SCREEN_ANN_ADD',
  AUTOSAVING = 'AUTO_SAVING',
  AUTOSAVING_LOADER = 'AUTO_SAVING_LOADER',
  ORG = 'ORG',
  GET_CUSTOM_DOMAINS = 'GET_CUSTOM_DOMAINS',
  ADD_CUSTOM_DOMAIN = 'ADD_CUSTOM_DOMAIN',
  SUBS = 'SUBS',
  ALL_USERS_FOR_ORG_LOADING = 'ALL_USERS_FOR_ORG_LOADING',
  ALL_USERS_FOR_ORG_LOADED = 'ALL_USERS_FOR_ORG_LOADED',
  USER_UPDATED = 'USER_UPDATED',
  SCREEN_UPDATE= 'SCREEN_UPDATE',
  DEFAULT_TOUR_LOADED = 'DEFAULT_TOUR_LOADED',
  ANALYTICS_TOTAL_TOUR_VIEW= 'ANALYTICS_TOTAL_TOUR_VIEW',
  ANALYTICS_TOUR_CONVERSION= 'ANALYTICS_TOUR_CONVERSION',
  ANALYTICS_STEPS_VISITED= 'ANALYTICS_STEPS_VISITED',
  ANALYTICS_ANN_INFO= 'ANALYTICS_ANN_INFO',
  ANALYTICS_LEADS = 'ANALYTICS_LEADS',
  ANALYTICS_LEAD_ACTIVITY = 'ANALYTICS_LEAD_ACTIVITY',
  TOUR_LOADED='TOUR_LOADED',
  UPDATE_ELPATH='UPDATE_ELPATH',
  SET_FEATURE_FOR_PLAN='SET_FEATURE_FOR_PLAN',
  REMOVE_SCREEN_DATA='REMOVE_SCREEN_DATA',
  SET_GLOBAL_CONFIG='SET_GLOBAL_CONFIG',
  SET_ALL_DEMOHUBS='SET_ALL_DEMOHUBS',
  DEMOHUB_LOADED='DEMOHUB_LOADED',
  SET_CURRENT_DEMOHUB_CONFIG='SET_CURRENT_DEMOHUB_CONFIG',
  SET_CURRENT_DEMOHUB_DATA='SET_CURRENT_DEMOHUB_DATA',
  UPDATE_DEMOHUB_DATA='UPDATE_DEMOHUB_DATA',
  DELETE_DEMOHUB_DATA='DELETE_DEMOHUB_DATA',
  CREATE_DEMOHUB_DATA='CREATE_DEMOHUB_DATA',
  DEMOHUB_LOADING = 'DEMOHUB_LOADING',
  CLEAR_CURRENT_DEMOHUB = 'CLEAR_CURRENT_DEMOHUB',
  SET_DH_CONFIG_UPLOAD_URL = 'SET_DH_CONFIG_UPLOAD_URL',
  ORG_WIDE_ANALYTICS = 'ORG_WIDE_ANALYTICS',
  ORG_WIDE_ANALYTICS_LOADING = 'ORG_WIDE_ANALYTICS_LOADING',
}

export default Action;
