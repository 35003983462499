import { addPointerEventsAutoToEl } from '../../../../utils';
import { Update } from './types';

export const applyFadeInTransitionToNode = (node: Node, originialOpacity: string): void => {
  if (node.nodeType === 1) {
    const element = node as HTMLElement;
    element.style.opacity = '0';
    element.style.transition = 'opacity 0.3s ease-out';
    const timer = setTimeout(() => {
      element.style.opacity = originialOpacity;
      clearTimeout(timer);
    }, 300);
  }
};

export function applyUpdateDiff(updates: Update[], el: Node): void {
  if (el && el.nodeType === Node.ELEMENT_NODE) {
    updates.forEach(update => {
      if (update.attrKey === 'style') {
        const allStyles = update.attrNewVal.split(';').filter(prop => !prop.match(/\s*transition/));
        update.attrNewVal = allStyles.join(' ; ');
      }
      if (update.shouldRemove) {
        (el as Element).removeAttribute(update.attrKey);
      } else {
        (el as Element).setAttribute(update.attrKey, update.attrNewVal);
      }
    });
    (el as HTMLElement).style.transition = 'all 0.3s ease-out';
    if (el.nodeName.toLowerCase() === 'body') {
      addPointerEventsAutoToEl(el as HTMLBodyElement);
    }
  }
}
